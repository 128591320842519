import React from "react"
import { graphql } from "gatsby"
import * as BlockContent from "@sanity/block-content-to-react"
import ProjectsStreetphotographyStartseite from "../pages/projects_streetphotography_startseite"
import ProjectsWithPreviewTextStartseite from "../pages/projects_projekte_startseite"
import ProjectsAnalogStartseite from "../pages/projects_analog_startseite"
import HeaderSlider from "../components/headerSlider"
import Footer from "../components/footer"
import Form from "../components/form"
import styled from "@emotion/styled"
import theme from "../shared/theme"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css"
import BlockRenderer from "../utils/textRenderer.js"
import Layout from "../shared/layout.js"
import { Helmet } from "react-helmet"

export const query = graphql`
  {
    sanityStartseite {
      headerslider {
        image {
          image_title
          image_overlay_headline
          image_overlay_text
          alt_text
          image_header {
            asset {
              fluid {
                src
              }
            }
          }
        }
      }
      headline_section_one
      _rawTextSectionOne
      headline_section_two
      _rawTextSectionTwo
      headline_section_three
      _rawTextSectionThree
      headline_anouncements
      _rawTextAnouncements
      display_anouncements
    }
  }
`

const titleStartPage =
  "Lumenas Photography - Street Photography aus Berlin und Europa sowie historische und analoge Fotografie"
const descriptionStartPage =
  "Street Photography aus Berlin und Europa. Künstlerische Projekte und Experimente im Bereich der analogen Fotografie. Historische Fotografie mit der Lochkamera und im Kollodium Nassplatten Verfahren."

//Startseite Lumenas.art
const IndexPage = ({ data }) => (
  <Layout>
    <Helmet>
      <html lang="de" />
      <title>{titleStartPage}</title>
      <meta name="description" content={descriptionStartPage}></meta>
    </Helmet>
    <Body>
      {/* Slider */}
      {data.sanityStartseite.headerslider === undefined ? (
        <div>"Loading"</div>
      ) : (
        <HeaderSlider imagesData={data.sanityStartseite.headerslider.image} />
      )}

      {/* Page Sections */}
      <SectionHeader id="streetphotography">
        <ScrollAnimation animateIn="fadeInLeft">
          {/* <h2>{data.sanityStartseite.headline_section_one}</h2> */}
          <h2>Street Photography</h2>
        </ScrollAnimation>
        <div className="blockTextRight">
          {console.log("section 1 ", data.sanityStartseite._rawTextSectionOne)}
          {/* <BlockContent
            blocks={data.sanityStartseite._rawTextSectionOne}
            serializers={{ types: { block: BlockRenderer } }}
          /> */}
          <p>
            Street Photography aus Berlin und anderen europäischen Städten,
            zusammengestellt in einer sich stetig erweiternden Werkserie. Street
            Photography erkundet dabei den urbanen Raum und den Alltag der
            Menschen, identifiziert örtliche Eigenheiten und Traditionen. Nichts
            ist inszeniert, als Fotograf trete ich zurück und agiere als
            Beobachter - als Flaneur, der sich in der Menge treiben lässt.
          </p>
          <p>
            In Anlehnung an Henri Cartier-Bressons Reisen und dem daraus
            entstandenen Bildband "Europäer" (1955) werden hierbei regionale
            Unterschiede und Gemeinsamkeiten, aber auch die politischen,
            gesellschaftlichen und wirtschaftlichen Gegensätze im
            zusammenwachsenden Europa reflektiert und die Menschen, die dieses
            ausmachen dokumentiert. Die Werkserie Street Photography legt somit
            ein Zeitzeugnis ab, ist eine bildliche Chronik, die das Dargestellte
            - den Alltag - künstlerisch aufwertet und kommentiert.
          </p>
        </div>
      </SectionHeader>
      <ProjectsStreetphotographyStartseite />
      <SectionHeader id="serien">
        <div className="blockTextLeft">
          <BlockContent
            blocks={data.sanityStartseite._rawTextSectionTwo}
            serializers={{ types: { block: BlockRenderer } }}
          />
        </div>
        <ScrollAnimation className="headlineRightBox" animateIn="fadeInRight">
          <h2>{data.sanityStartseite.headline_section_two}</h2>
        </ScrollAnimation>
      </SectionHeader>
      <ProjectsWithPreviewTextStartseite />
      <SectionHeader id="analog">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>{data.sanityStartseite.headline_section_three}</h2>
        </ScrollAnimation>
        <div className="blockTextRight">
          {/* <BlockContent
            blocks={data.sanityStartseite._rawTextSectionThree}
            serializers={{ types: { block: BlockRenderer } }}
          /> */}
          <p>
            Analoge und historische Fototechniken von 1850 bis heute. Die
            Sektion Lochkamera zeigt Fotografien, die mit selbst gebauten oder
            mit umgebauten Fotoapparaten realisiert wurden. Daneben stehen
            Fotografien im analogen Mittelformat, aufgenommen mit der Hasselblad
            oder Rolleiflex. Die Porträts im Kollodium Nassplatten Verfahren
            führen dagegen in eine Zeit zurück, als an Film als Trägermaterial
            für die Aufnahmen noch nicht zu denken war.
          </p>
        </div>
      </SectionHeader>
      <ProjectsAnalogStartseite />
      {/* <SectionHeader>
        <ScrollAnimation className="headlineRightBox" animateIn="fadeInRight">
          <h2>{data.sanityStartseite.headline_anouncements}</h2>
        </ScrollAnimation>
      </SectionHeader> */}
      {/* <NewsSection id="news">
        <div className="blockTextNews">
          {console.log("announcements ", data.sanityStartseite)}
          <BlockContent
            blocks={data.sanityStartseite._rawTextAnouncements}
            serializers={{ types: { block: BlockRenderer } }}
          />
        </div>
      </NewsSection> */}
      <Form />
      <SeperatorFooter />
      <Footer />
    </Body>
  </Layout>
)

const Body = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: ${theme.colors.bodyBackground};

  h2 {
    font-family: ${theme.fontFamilyHeading};
    color: ${theme.colors.darkFont};
    font-weight: bold;
    font-size: ${theme.fontsizes.h1MediumScreen};
    text-transform: uppercase;
    margin-bottom: 0;
    line-height: 1;
  }

  @media all and (max-width: 768px) {
    h2 {
      font-size: ${theme.fontsizes.h1SmallScreen};
      margin-bottom: 0;
      line-height: 1;
    }
  }
`

//immer Überschrift + Einführungstext in Sektion
const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 100%;
  padding: ${theme.spaces.standardBlockTopEnd} ${theme.spaces.standardPadding};

  div {
    width: 100%;
    overflow: hidden;
  }

  .blockTextRight {
    font-family: ${theme.fontFamily};
    color: ${theme.colors.darkFont};
    font-size: ${theme.fontsizes.textMediumScreen};
    line-height: ${theme.spaces.textMediumScreenLineHeight};
    text-align: justify;
    padding-left: ${theme.spaces.standardPadding};
    border-left: ${theme.lineStrength.seperators} solid ${theme.colors.darkFont};

    :nth-of-type(2n + 1) {
      padding-left: 0;
      padding-right: ${theme.spaces.standardPadding};
    }
  }

  .blockTextLeft {
    font-family: ${theme.fontFamily};
    color: ${theme.colors.darkFont};
    font-size: ${theme.fontsizes.textMediumScreen};
    line-height: ${theme.spaces.textMediumScreenLineHeight};
    text-align: justify;
    padding-left: ${theme.spaces.standardPadding};
    border-right: ${theme.lineStrength.seperators} solid
      ${theme.colors.darkFont};

    :nth-of-type(2n + 1) {
      padding-left: 0;
      padding-right: ${theme.spaces.standardPadding};
    }
  }

  .headlineRightBox {
    text-align: right;
  }

  @media all and (max-width: 768px) {
    display: block;

    div {
      width: auto;
    }

    h2 {
      font-size: calc(2 * ${theme.fontsizes.h2SmallScreen})
    }

    .blockTextRight {
      // width: 100%;
      margin-top: ${theme.spaces.standardBlockTopEnd};
      // margin-right: ${theme.spaces.standardPadding};
      padding-left: ${theme.spaces.standardPadding};
      font-size: ${theme.fontsizes.textSmallScreen};
      line-height: ${theme.spaces.textSmallScreenLineHeight};
    }

    .blockTextLeft {
      margin-bottom: ${theme.spaces.standardBlockTopEnd};
      padding-right: 0;
      font-size: ${theme.fontsizes.textSmallScreen};
      line-height: ${theme.spaces.textSmallScreenLineHeight};
    }

    .headlineRightBox {
      padding-right: 0;
      width: 100%
    }
  }
`

// const NewsSection = styled.section`
//   padding: 0 ${theme.spaces.standardPadding};

//   .blockTextNews {
//     font-family: ${theme.fontFamily};
//     color: ${theme.colors.darkFont};
//     font-size: ${theme.fontsizes.textMediumScreen};
//     line-height: ${theme.spaces.textMediumScreenLineHeight};
//     text-align: justify;
//     padding-bottom: ${theme.spaces.standardPadding};

//     a {
//       color: ${theme.colors.darkFont};
//     }
//   }

//   @media all and (max-width: 768px) {
//     .blockTextNews {
//       font-size: ${theme.fontsizes.textSmallScreen};
//       line-height: ${theme.spaces.textSmallScreenLineHeight};
//       width: 85%;
//     }
//   }
// `

const SeperatorFooter = styled.div`
  width: 100%;
  border-bottom: ${theme.lineStrength.seperators} solid ${theme.colors.darkFont};
  margin-top: ${theme.spaces.standardBlockTopEnd};
`

export default IndexPage
