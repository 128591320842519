import React, { useState } from "react"
import styled from "@emotion/styled"
import theme from "../shared/theme"
import SeparatorLine from "../elements/separatorLine"
import ScrollAnimation from "react-animate-on-scroll"
import emailjs from "emailjs-com"
import { useForm } from "react-hook-form"

const Form = () => {
  const stateData = {
    name: "",
    email: "",
    telephone: "",
    betreff: "",
    nachricht: ""
  }

  const [submitMessage, setSubmitMessage] = useState(false)
  const [errorMessageSubmit, seterrorMessageSubmitSubmit] = useState(false)
  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: { stateData }
  })

  //übermittelt per emailjs Formulardaten an E-Mail-Postfach des Administrators
  async function sendEmail(formData) {
    let templateParams = {
      name: formData.name,
      email: formData.email,
      telephone: formData.telephone,
      betreff: formData.betreff,
      nachricht: formData.nachricht
    }
    try {
      await emailjs
        .send(
          "gmail",
          "lumenas_art_kontakt",
          templateParams,
          "user_rrLLQMk9ol94iMHO6Yxns"
        )
        .then(
          result => {
            console.log(result.text)
            setSubmitMessage(true)
            reset({ ...stateData })
            setTimeout(() => {
              setSubmitMessage(false)
            }, 3000)
          },
          error => {
            console.log(error.text)
            seterrorMessageSubmitSubmit(true)
            reset({ ...stateData })
            setTimeout(() => {
              seterrorMessageSubmitSubmit(false)
            }, 3000)
          }
        )
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <React.Fragment>
      <Section>
        <SeparatorLine />
        <Content>
          <Text>
            <ScrollAnimation animateIn="fadeInLeft">
              <h2>Kontakt</h2>
              <p>Du hast eine Frage? Schicke mir eine Nachricht!</p>
            </ScrollAnimation>
          </Text>
          <FormContent>
            <form onSubmit={handleSubmit(sendEmail)}>
              <FormContentBlock>
                <label htmlFor="name">Name</label>
                <Input
                  id="name"
                  name="name"
                  placeholder="Pflichtfeld"
                  ref={register({
                    required: "Bitte ausfüllen",
                    pattern: {
                      value: /[^0-9<>{}]/,
                      message: "Nur gültige Namen aus Buchstaben erlaubt"
                    }
                  })}
                />
                <ValidationContainer>
                  {errors.name && errors.name.message}
                </ValidationContainer>
              </FormContentBlock>
              <FormContentBlock>
                <label htmlFor="email">Mail</label>
                <Input
                  id="email"
                  name="email"
                  placeholder="Pflichtfeld"
                  ref={register({
                    required: "Bitte ausfüllen",
                    pattern: {
                      value: /[a-zA-Z0-9]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}/,
                      message: "Ungültige E-Mail"
                    }
                  })}
                />
                <ValidationContainer>
                  {errors.email && errors.email.message}
                </ValidationContainer>
              </FormContentBlock>
              <FormContentBlock>
                <label htmlFor="telephone">Telefon</label>
                <Input
                  id="telephone"
                  name="telephone"
                  type="tel"
                  ref={register({
                    pattern: {
                      value: /[^a-zA-Z<>{}]+/,
                      message:
                        "Ungültige Telefonnummer - Ziffern,/ und Space erlaubt"
                    }
                  })}
                />
                <ValidationContainer>
                  {errors.telephone && errors.telephone.message}
                </ValidationContainer>
              </FormContentBlock>
              <FormContentBlock>
                <label htmlFor="betreff">Betreff</label>
                <Input
                  id="betreff"
                  name="betreff"
                  placeholder="Pflichtfeld"
                  ref={register({
                    required: "Bitte ausfüllen",
                    pattern: {
                      value: /[a-zA-Z0-9äÄöÖüÜ ,;.!?+\-/#]/,
                      message: "Nur Ziffern und Buchstaben erlaubt"
                    }
                  })}
                />
                <ValidationContainer>
                  {errors.betreff && errors.betreff.message}
                </ValidationContainer>
              </FormContentBlock>
              <FormContentBlock>
                <label htmlFor="nachricht">An(Frage)</label>
                <Textarea
                  id="nachricht"
                  name="nachricht"
                  placeholder="Pflichtfeld - max. 500 Zeichen"
                  ref={register({
                    required: "Bitte ausfüllen",
                    pattern: {
                      value: /[a-zA-Z0-9äÄöÖüÜ ,;.!?+\-/#]/,
                      message: "Nur Buchstaben und Ziffern erlaubt"
                    }
                  })}
                />
                <ValidationContainer>
                  {errors.nachricht && errors.nachricht.message}
                </ValidationContainer>
              </FormContentBlock>
              <FormContentBlock>
                <SendButton
                  aria-label="Formular absenden"
                  title="Formular absenden"
                  type="submit"
                >
                  Absenden
                </SendButton>
              </FormContentBlock>
              {submitMessage && (
                <SubmitMessage>
                  "Vielen Dank. Deine Nachricht wurde erfolgreich abgesendet."
                </SubmitMessage>
              )}
              {errorMessageSubmit && (
                <SubmitMessage>
                  "Leider gab es einen Fehler. Deine Nachricht konnte nicht
                  zugesellt werden."
                </SubmitMessage>
              )}
            </form>
          </FormContent>
        </Content>
      </Section>
    </React.Fragment>
  )
}

const Section = styled.div`
  padding: 0 ${theme.spaces.standardPadding} 0 ${theme.spaces.standardPadding};
  font-family: ${theme.fontFamily};
  color: ${theme.colors.darkFont};
  font-size: ${theme.fontsizes.textMediumScreen};
  line-height: ${theme.spaces.textMediumScreenLineHeight};

  @media all and (max-width: 768px) {
    font-size: ${theme.fontsizes.textSmallScreen};
    line-height: ${theme.spaces.textSmallScreenLineHeight};
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${theme.spaces.standardBlockTopEnd};

  @media all and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`

const Text = styled.div`
  padding-right: ${theme.spaces.standardPadding};

  p {
    color: ${theme.colors.counterColorRed};
  }

  @media all and (max-width: 768px) {
    h2 {
      font-size: calc(2 * ${theme.fontsizes.h2SmallScreen});
    }
  }
`

const FormContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  @media all and (max-width: 768px) {
    margin-top: ${theme.spaces.standardPadding};
    width: 100%;
  }
`

const FormContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${theme.spaces.smallSpacing};
`

const Input = styled.input`
  height: 3vw;
  background-color: transparent;
  border: 0;
  border-bottom: ${theme.lineStrength.seperators} solid;
  outline: none;
  border-radius: 0;
  font-family: ${theme.fontFamily};
  font-size: ${theme.fontsizes.textMediumScreen};
  line-height: ${theme.spaces.textMediumScreenLineHeight};

  @media all and (max-width: 768px) {
    height: 10vw;
    font-size: ${theme.fontsizes.textSmallScreen};
    line-height: ${theme.spaces.textSmallScreenLineHeight};
  }
`

const SendButton = styled.button`
  padding: 1vw 2vw;
  margin: ${theme.spaces.standardPadding} auto;
  background-color: transparent;
  border: ${theme.lineStrength.seperators} solid;
  outline: none;
  border-radius: 0;
  font-family: ${theme.fontFamily};
  font-size: ${theme.fontsizes.textMediumScreen};

  :hover {
    background-color: ${theme.colors.darkFont};
    color: ${theme.colors.bodyBackground};
  }

  @media all and (max-width: 768px) {
    padding: 4vw 8vw;
    font-size: ${theme.fontsizes.textSmallScreen};
  }
`

const Textarea = styled.textarea`
  height: 10vw;
  background-color: transparent;
  border: 0;
  border-bottom: ${theme.lineStrength.seperators} solid;
  outline: none;
  border-radius: 0;
  font-family: ${theme.fontFamily};
  font-size: ${theme.fontsizes.textMediumScreen};
  line-height: ${theme.spaces.textMediumScreenLineHeight};

  @media all and (max-width: 768px) {
    height: 25vw;
    font-size: ${theme.fontsizes.textSmallScreen};
    line-height: ${theme.spaces.textSmallScreenLineHeight};
  }
`
const SubmitMessage = styled.div`
  font-family: ${theme.fontFamily};
  font-size: ${theme.fontsizes.textMediumScreen};
  font-weight: bold;

  @media all and (max-width: 768px) {
    font-size: ${theme.fontsizes.textSmallScreen};
  }
`

const ValidationContainer = styled.div`
  height: 2vw;
  color: ${theme.colors.counterColorRed};
`

export default Form
