import React from "react"
import styled from "@emotion/styled"
import theme from "../shared/theme"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <React.Fragment>
      <Container>
        <Logo>
          <span>Lumenas Photography</span>
        </Logo>
        <LinkContainer>
          <div>
            <StyledLink to="/aboutMe/">About Me</StyledLink>
          </div>
          <div>
            <StyledLink to="/bookreview_overview/">Bücher</StyledLink>
          </div>
          <div>
            <StyledLink to="/impressum/">Impressum</StyledLink>
          </div>
        </LinkContainer>
      </Container>
    </React.Fragment>
  )
}

const Container = styled.section`
  background-color: ${theme.colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`

const Logo = styled.div`
  padding: ${theme.spaces.standardPadding};

  span {
    font-family: ${theme.fontFamilyHeading};
    font-size: ${theme.fontsizes.h3MediumScreen};
    color: ${theme.colors.darkFont};
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
  }

  @media all and (max-width: 768px) {
    span {
      font-size: ${theme.fontsizes.smallTextSmallScreen};
      line-height: 0.5;
    }
  }
`

const LinkContainer = styled.div`
  font-family: ${theme.fontFamily};
  font-size: ${theme.fontsizes.textMediumScreen};
  color: ${theme.colors.darkFont};
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${theme.spaces.standardPadding};

  div {
    padding-left: ${theme.spaces.standardPadding};
  }

  div a {
    text-decoration: none;
    color: ${theme.colors.darkFont};
  }

  @media all and (max-width: 768px) {
    font-size: ${theme.fontsizes.textSmallScreen};
    flex-direction: column;
    margin: auto;

    div {
      padding-left: 0;
      margin-bottom: ${theme.spaces.standardPadding};
    }
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.darkFont};

  :hover {
    font-weight: bold;
  }
`

export default Footer
