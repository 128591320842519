import React from "react"
import { Fade } from "react-slideshow-image"
import "react-slideshow-image/dist/styles.css"
import styled from "@emotion/styled"
import theme from "../shared/theme"
import "animate.css"

import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const properties = {
  duration: 4000,
  transitionDuration: 3000,
  infinite: true,
  indicators: false,
  arrows: false
}

const HeaderSlider = imagesData => {
  return (
    <React.Fragment>
      <PhotoFrame>
        <Fade {...properties}>
          {imagesData.imagesData.map((item, index) => (
            <Picture
              role="img"
              alt={item.alt_text}
              key={index}
              className="animated fadeIn delay-1s"
              style={{
                backgroundImage: `url(${item.image_header.asset.fluid.src})`
              }}
            >
              <Banner>
                {item.image_overlay_headline ? item.image_overlay_headline : ""}
              </Banner>
              <span>
                {item.image_overlay_text ? item.image_overlay_text : ""}
              </span>
            </Picture>
          ))}
        </Fade>
        <Logo>
          Lumenas <span> Street Photography & Analoge Fotografie</span>
        </Logo>
      </PhotoFrame>
      <Bouncer>
        <div className="customBounce bounce-2">
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </Bouncer>
    </React.Fragment>
  )
}

const PhotoFrame = styled.div`
  width: 90%;
  border: 2vw solid ${theme.colors.white};
`

const Picture = styled.div`
  height: 60vw;
  width: 100%;
  background-size: cover;

  span {
    font-family: ${theme.fontFamilyHeading};
    font-size: ${theme.fontsizes.subBannerMediumScreen};
    color: ${theme.colors.white};
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 0 0 10%;
    line-height: 1;
  }

  @media all and (max-width: 768px) {
    span {
      font-size: ${theme.fontsizes.subBannerSmallScreen};
    }
  }
`

const Banner = styled.div`
  font-family: ${theme.fontFamilyHeading};
  color: ${theme.colors.white};
  font-weight: bold;
  font-size: ${theme.fontsizes.bannerMediumScreen};
  text-transform: uppercase;
  margin-bottom: 0;
  padding: 30% 0 0 10%;
  line-height: 1;

  @media all and (max-width: 768px) {
    font-size: ${theme.fontsizes.bannerSmallScreen};
  }
`

const Logo = styled.h1`
  position: absolute;
  right: 1.5vw;
  top: 2vw;
  padding-left: 0.5vw;
  text-orientation: sideways;
  writing-mode: vertical-lr;
  font-size: ${theme.fontsizes.subBannerMediumScreen};
  color: ${theme.colors.darkFont};
  font-family: ${theme.fontFamilyHeading};
  font-weight: bold;
  text-transform: uppercase;

  span {
    font-size: ${theme.fontsizes.smallTextMediumScreen};
    font-weight: normal;
    text-decoration: underline;
  }

  @media all and (max-width: 768px) {
    font-size: ${theme.fontsizes.subBannerSmallScreen};
    right: 0;

    span {
      font-size: calc(0.9 * ${theme.fontsizes.smallTextSmallScreen});
    }
  }
`

const Bouncer = styled.div`
    position: sticky;
    display: flex;
    font-size: ${theme.fontsizes.subBannerMediumScreen};

    bottom: 20%;
    margin: 0 50%;
    color: ${theme.colors.white};

    .customBounce {
        align-self: flex-end;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        transform-origin: bottom;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .bounce-2 {
        animation-name: bounce-2;
        animation-timing-function: ease-out;
    }
    @keyframes bounce-2 {
        0%   { transform: translateY(0); }
        50%  { transform: translateY(-2vw); }
        100% { transform: translateY(0); }
`

export default HeaderSlider
