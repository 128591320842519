/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
// import { useStaticQuery, graphql } from "gatsby"

//To Do......
function SEO({ meta, title, description }) {
  // const { site } = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           title
  //           description
  //           author
  //         }
  //       }
  //     }
  //   `
  // )

  const lang = "de"
  // const description =
  //   "LUMENAS Photography - Street Photography aus Berlin und anderen Städten Europas sowie Serien im Bereich der künstlerischen und analogen Fotografie."
  const metaDescription = description
  // || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      // lang="de"
      title={title}
      // title="LUMENAS - Street Photography Europa und analoge Fotografie"
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        }
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default SEO
