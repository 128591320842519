import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import Image from "gatsby-image"
import theme from "../shared/theme"
import Button from "../elements/button"
// import SeparatorLine from "../elements/separatorLine"

const ProjectsAnalogStartseite = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityProjectAnalog {
        edges {
          node {
            project_headline
            alt_text
            preview_image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
            slug {
              _key
              _type
              current
            }
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Section>
        <StyledPictureContainer>
          {data.allSanityProjectAnalog === null
            ? ""
            : data.allSanityProjectAnalog.edges.map((item, index) => (
                <StyledPicture key={index}>
                  <SEO
                    title={
                      item.node.project_headline
                        ? item.node.project_headline
                        : ""
                    }
                    description={item.node.alt_text ? item.node.alt_text : ""}
                  />
                  {item.node.preview_image ? (
                    <a href={`analoge-fotografie/${item.node.slug.current}`}>
                      <Image
                        fluid={
                          item.node.preview_image.asset.fluid
                            ? item.node.preview_image.asset.fluid
                            : null
                        }
                      />
                    </a>
                  ) : null}
                  <ImageSubline>
                    <div>
                      <h3>
                        {item.node.project_headline
                          ? item.node.project_headline
                          : ""}
                      </h3>
                    </div>
                    <div>
                      <Button
                        title={"zum Projekt " + item.node.project_headline}
                        ariaLabel={"zum Projekt " + item.node.project_headline}
                        linkTo={
                          item.node.slug
                            ? `analoge-fotografie/${item.node.slug.current}`
                            : "/"
                        }
                      >
                        zum Projekt
                      </Button>
                    </div>
                  </ImageSubline>
                </StyledPicture>
              ))}
        </StyledPictureContainer>
        {/* <SeparatorLine /> */}
      </Section>
    </React.Fragment>
  )
}

const Section = styled.div`
  padding: 0 ${theme.spaces.standardPadding} 0 ${theme.spaces.standardPadding};
`

const StyledPictureContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(calc(100% / 3 - ${theme.spaces.standardPadding}), 1fr)
  );
  grid-gap: ${theme.spaces.standardPadding};

  @media all and (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 1), 1fr));
  }
`

const StyledPicture = styled.div`
  margin-bottom: ${theme.spaces.standardPadding};

  // &:hover{
  //   opacity: .5;
  //   cursor: pointer;
  // }

  // @media all and (max-width: 768px) {
  //   margin: 0.2vw;

  //   &:hover {
  //     opacity: 1;
  //     cursor: auto;
  //   }
  // }
`

const ImageSubline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: ${theme.lineStrength.seperators} solid ${theme.colors.darkFont};
  align-items: center;

  div > h3 {
    font-size: ${theme.fontsizes.h2MediumScreen};
    font-family: ${theme.fontFamilyHeading};
    color: ${theme.colors.darkFont};
    font-weight: bold;
    text-transform: uppercase;
  }

  @media all and (max-width: 768px) {
    div > h3 {
      font-size: ${theme.fontsizes.h3SmallScreen};
    }
    height: 8vw;
  }
`

export default ProjectsAnalogStartseite
