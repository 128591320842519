import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import Image from "gatsby-image"
import * as BlockContent from "@sanity/block-content-to-react"
import theme from "../shared/theme"
import Button from "../elements/button"
import SeparatorLine from "../elements/separatorLine"
import BlockRenderer from "../utils/textRenderer.js"

const ProjectsWithPreviewTextStartseite = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityProjectProjekte {
        edges {
          node {
            project_headline
            alt_text
            preview_image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
            _rawProjectPreviewText
            slug {
              _key
              _type
              current
            }
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Section>
        {data.allSanityProjectProjekte === null
          ? ""
          : data.allSanityProjectProjekte.edges.map((item, index) =>
              index % 2 === 0 ? (
                <div className="itemContainer" key={index}>
                  <StyledPicture>
                    <div className="image">
                      <SEO
                        title={
                          item.node.project_headline
                            ? item.node.project_headline
                            : ""
                        }
                        description={
                          item.node.alt_text ? item.node.alt_text : ""
                        }
                      />
                      {item.node.preview_image ? (
                        <a href={`serien/${item.node.slug.current}`}>
                          <Image
                            fluid={
                              item.node.preview_image.asset.fluid
                                ? item.node.preview_image.asset.fluid
                                : null
                            }
                            alt={item.node.alt_text ? item.node.alt_text : ""}
                          />
                        </a>
                      ) : null}
                    </div>
                    <div>
                      <ImageSubline>
                        <div>
                          <h3>
                            {item.node.project_headline
                              ? item.node.project_headline
                              : ""}
                          </h3>
                        </div>
                        <div>
                          <Button
                            title={"zum Projekt " + item.node.project_headline}
                            ariaLabel={
                              "zum Projekt " + item.node.project_headline
                            }
                            linkTo={
                              item.node.slug
                                ? `serien/${item.node.slug.current}`
                                : "/"
                            }
                          >
                            zum Projekt
                          </Button>
                        </div>
                      </ImageSubline>
                      <PreviewText>
                        <BlockContent
                          blocks={
                            item.node._rawProjectPreviewText
                              ? item.node._rawProjectPreviewText
                              : ""
                          }
                          serializers={{ types: { block: BlockRenderer } }}
                        />
                      </PreviewText>
                    </div>
                  </StyledPicture>
                </div>
              ) : (
                //Container Bild rechts
                <div className="itemContainer" key={index}>
                  <StyledPicture>
                    <div>
                      <SEO
                        title={
                          item.node.project_headline
                            ? item.node.project_headline
                            : ""
                        }
                        description={
                          item.node.alt_text ? item.node.alt_text : ""
                        }
                      />
                      <ImageSubline>
                        <div>
                          <h3>
                            {item.node.project_headline
                              ? item.node.project_headline
                              : ""}
                          </h3>
                        </div>
                        <div>
                          <Button
                            title={"zum Projekt " + item.node.project_headline}
                            ariaLabel={
                              "zum Projekt " + item.node.project_headline
                            }
                            linkTo={
                              item.node.slug
                                ? `serien/${item.node.slug.current}`
                                : "/"
                            }
                          >
                            zum Projekt
                          </Button>
                        </div>
                      </ImageSubline>
                      <PreviewText>
                        <BlockContent
                          blocks={
                            item.node._rawProjectPreviewText
                              ? item.node._rawProjectPreviewText
                              : ""
                          }
                          serializers={{ types: { block: BlockRenderer } }}
                        />
                      </PreviewText>
                    </div>
                    <div className="image">
                      {item.node.preview_image ? (
                        <a href={`serien/${item.node.slug.current}`}>
                          <Image
                            fluid={
                              item.node.preview_image.asset.fluid
                                ? item.node.preview_image.asset.fluid
                                : null
                            }
                            alt={item.node.alt_text ? item.node.alt_text : ""}
                          />
                        </a>
                      ) : null}
                    </div>
                  </StyledPicture>
                </div>
              )
            )}
        <SeparatorLine />
      </Section>
    </React.Fragment>
  )
}

const Section = styled.div`
  padding: 0 ${theme.spaces.standardPadding} 0 ${theme.spaces.standardPadding};
  display: grid;
  grid-template-columns: 1fr;

  .image {
    :nth-of-type(2n + 1) {
      margin-right: ${theme.spaces.standardPadding};
    }

    :nth-of-type(2n + 0) {
      margin-left: ${theme.spaces.standardPadding};
    }
  }

  .itemContainer {
    margin-bottom: ${theme.spaces.standardPadding};
    }
  }
`

const StyledPicture = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    width: 50%;
  }

  @media all and (max-width: 768px) {
    display: block;

    > div {
      width: 100%;
    }

    :nth-of-type(2n + 1) {
      .image {
        margin: ${theme.spaces.standardPadding} 0
          ${theme.spaces.standardPadding} 0;

        :first-of-type {
          margin-top: 0;
        }
      }
    }
  }
`

const ImageSubline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: ${theme.lineStrength.seperators} solid ${theme.colors.darkFont};
  align-items: center;
  color: ${theme.colors.darkFont};

  div > h3 {
    font-size: ${theme.fontsizes.h2MediumScreen};
    font-family: ${theme.fontFamilyHeading};
    font-weight: bold;
    text-transform: uppercase;

    @media all and (max-width: 768px) {
      font-size: ${theme.fontsizes.h3SmallScreen};
    }
  }

  @media all and (max-width: 768px) {
    margin-bottom: ${theme.spaces.smallSpacing};
  }
`

const PreviewText = styled.div`
  font-family: ${theme.fontFamily};
  color: ${theme.colors.darkFont};
  font-size: ${theme.fontsizes.textMediumScreen};
  line-height: ${theme.spaces.textMediumScreenLineHeight};
  text-align: justify;

  @media all and (max-width: 768px) {
    font-size: ${theme.fontsizes.textSmallScreen};
    line-height: ${theme.spaces.textSmallScreenLineHeight};
  }
`

export default ProjectsWithPreviewTextStartseite
